<template>
    <div class="tabs">
        <router-link :to="item.path" :class="item.path == $route.fullPath ? 'tab active' : 'tab'"
                     v-for="(item, index) in $store.state.routerHistory"
                     :key="index"
        >{{item.label}} <i class="el-icon-close" ref="icon" @click="routerHistoryDel(index)"
                           @mouseover="mouseOver(index)"
                           @mouseleave="mouseLeave(index)"></i>
        </router-link>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                routerHistory: []
            }
        },
        methods: {
            mouseOver: function (index) {
                this.$refs.icon[index].setAttribute("class", "el-icon-circle-close")
            },
            mouseLeave: function (index) {
                this.$refs.icon[index].setAttribute("class", "el-icon-close")
            },
            routerHistoryDel(index) {
                this.$store.commit('delRouterHistory', index)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tabs {
        flex: 1;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;

        .active {
            color: #409EFF !important;
        }

        .tab {
            cursor: pointer;
            position: relative;
            padding: 0 20px;
            border-right: 1px solid #CCCCCC;
            color: #666;
            font-size: 90%;
            line-height: 60px;
            text-decoration: none;
        }

        .tab:last-child {
            border: none;
        }
    }
</style>
