<template>
  <el-container>
    <el-aside width="">
      <el-header class="header-left">
        <div class="logo">
          <img :src="logo" alt="logo" />
        </div>
        <div class="title" v-if="!isCollapse">
          围手术管理系统
        </div>
      </el-header>
      <el-menu
        class="el-menu-vertical-demo"
        noborder
        router
        :default-active="$route.path"
        unique-opened
        v-loading="loading"
        :collapse="isCollapse"
      >
        <template v-for="(menu, index) in menuItems">
          <el-submenu
            v-if="menu.children && menu.children.length >= 1"
            :index="menu.path"
            :key="index"
          >
            <template slot="title">
              <i :class="menu.icon"></i>
              <span slot="title">{{ menu.label }}</span>
            </template>
            <el-menu-item
              v-for="(sb, sid) in menu.children"
              :key="sid"
              :index="sb.path"
              @click="handleSelect(sb)"
              >{{ sb.label }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :index="menu.path"
            :key="index"
            @click="handleSelect(menu)"
          >
            <i :class="menu.icon"></i>
            <span slot="title">{{ menu.label }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="header-right">
        <div class="iconButton" @click="handleCollapse">
          <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </div>
        <router-history />
        <router-link class="iconButton" to="/main/message">
          <el-badge
            value="..."
            class="badge"
            v-if="$store.state.messages.length > 0"
          >
            <i class="el-icon-message"></i>
          </el-badge>
          <i class="el-icon-message" v-else></i>
        </router-link>
        <el-dropdown class="dropdownMenu" trigger="click">
          <span class="username">
            <!--                        <el-avatar src="//cdn.zhack.com/zkui/img/avatar.png"></el-avatar>-->
            {{ $store.getters.username }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link class="link" to="/main">
              <el-dropdown-item>首页</el-dropdown-item>
            </router-link>
            <router-link class="link" to="/main/password">
              <el-dropdown-item>修改密码</el-dropdown-item>
            </router-link>
            <router-link class="link" to="/logout">
              <el-dropdown-item divided>退出登陆</el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Logo from '@/assets/logo0.png';
import menu from '@/data/menu';
import routerHistory from './routerHistory';

export default {
  components: {
    routerHistory,
  },
  data() {
    return {
      loading: false,
      logo: Logo,
      isCollapse: true,
      menuItems: [], //menu.menuItems
    };
  },
  methods: {
    handleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleSelect(item) {
      this.$store.commit('setRouterHistory', {
        label: item.label,
        path: item.path,
      });
    },
    async getData() {
      this.loading = true;
      const resp = await this.$http.get(`/auth/menu`);
      this.menuItems = resp.data.data || [];
      // if (this.menuItems.length === 0 && this.$store.getters.username === 'admin') {
      if (this.$store.getters.username === 'admin') {
        this.menuItems = menu.menuItems;
      }
      this.loading = false;
    },
    resize() {
      let clientWidth =
        document.documentElement.clientWidth || document.body.clientHeight;
      if (clientWidth < 768) {
        //当屏幕小于768时，设置Vuex里的数据为true
        this.isCollapse = true;
      } else {
        //反之，设置Vuex里的数据为false
        this.isCollapse = false;
      }
    },
  },
  mounted() {
    window.onresize = () => this.resize();
    this.getData();
    this.resize();
  },
};
</script>

<style lang="scss" scoped>
.header-left {
  background: #2081c0;
  display: flex;
  flex-direction: row;
  line-height: 60px;

  .logo {
    vertical-align: middle;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 6px;
    }
  }

  .title {
    color: #fff;
    margin-left: 10px;
    flex: 1;
  }
}

.el-aside {
  /*background-color: #eeeeee;*/
  color: #333;
  border-right: 1px solid #e6e6e6;
}

.link {
  text-decoration: none;
}

.el-menu {
  border-right: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  border: none;
}

.header-right {
  padding: 0;
  line-height: 60px;
  color: #333;
  border-bottom: 1px solid #eeeeee;
  line-height: 60px;
  display: flex;
  flex-direction: row;

  .iconButton {
    width: 60px;
    margin: 0;
    padding: 0;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    color: #777;

    .el-badge {
      line-height: 20px;
      height: 20px;
      padding: 0;
    }

    i {
      /*font-size: 120%;*/
    }
  }

  .dropdownMenu {
    cursor: pointer;
    padding: 0 20px;

    .user-avatar {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
