const data = {
    menuItems: [
        {
            id: '10',
            label: '系统首页',
            path: '/main/dashboard',
            icon: 'el-icon-view',
        },
        // {
        //     id: '20',
        //     label: '预诊记录',
        //     path: '/main/advance',
        //     icon: 'el-icon-film',
        // },
        {
            id: '30',
            label: '手术预约',
            path: '/main/appoint',
            icon: 'el-icon-film',
            children: [
                {
                    id: '30-1',
                    label: '手术预约',
                    path: '/main/appoint/form',
                },
                {
                    id: '30-2',
                    label: '预约日历',
                    path: '/main/appoint/calendar',
                },
                {
                    id: '30-3',
                    label: '预约记录',
                    path: '/main/appoint',
                },
                {
                    id: '30-4',
                    label: '异常数据',
                    path: '/main/appoint/outliers',
                },
                // {
                //     id: '30-4',
                //     label: '特需通道',
                //     path: '/main/appoint/vip',
                // },
                // {
                //     label: '调手术记录',
                //     path: '/main/reschedule',
                // },
                // {
                //     id: '30-5',
                //     label: '手术室状态',
                //     path: '/main/theater',
                //     icon: 'el-icon-film',
                // },
                // {
                //     id: '30-6',
                //     label: '手术叫号',
                //     path: '/main/theater/prepare',
                //     icon: 'el-icon-film',
                // },
            ]
        },
        // {
        //     id: '40',
        //     label: '患者术前教育',
        //     path: '/main/training',
        //     icon: 'el-icon-film',
        //     children: [
        //         {
        //             id: '40-1',
        //             label: '术前教育内容管理',
        //             path: '/main/training',
        //         },
        //         {
        //             id: '40-2',
        //             label: '内容推送设置',
        //             path: '/main/training/settings',
        //         },
        //     ]
        // },
        // {
        //     id: '50',
        //     label: '自助打印知情同意书',
        //     path: '/main/agreement',
        //     icon: 'el-icon-film',
        // },
        // {
        //     id: '60',
        //     label: '术前评估',
        //     path: '/main/assess',
        //     icon: 'el-icon-film',
        // },
        // {
        //     id: '70',
        //     label: '手术记录',
        //     path: '/main/operation',
        //     icon: 'el-icon-film',
        // },
        {
            id: '80',
            label: '手术排班',
            path: '/main/worksheet',
            icon: 'el-icon-school',
            children: [
                {
                    id: '81',
                    label: '手术排班表',
                    path: '/main/worksheet',
                },
                {
                    id: '82',
                    label: '临时停手术',
                    path: '/main/worksheet/special',
                },
                {
                    id: '83',
                    label: '临时排班',
                    path: '/main/worksheet/overtime',
                },
                {
                    id: '84',
                    label: '节假日',
                    path: '/main/worksheet/vacation',
                },
                {
                    id: '85',
                    label: '周期性排班',
                    path: '/main/worksheet/cycle',
                },
            ]
        },
        // {
        //     id: '90',
        //     label: '资源管理',
        //     path: '/main/files',
        //     icon: 'el-icon-c-scale-to-original',
        //     children: [
        //         {
        //             id: '90-1',
        //             label: '文件管理',
        //             path: '/main/files/docs',
        //         },
        //         {
        //             id: '90-2',
        //             label: '患教视频',
        //             path: '/main/files/medias',
        //         },
        //         {
        //             id: '90-3',
        //             label: '知识库',
        //             path: '/main/files/wiki',
        //         },
        //     ]
        // },
        {
            id: '95',
            label: '术后医嘱',
            path: '/main/advice',
            icon: 'el-icon-s-order',
        },
        {
            id: '100',
            label: '统计报表',
            path: '/main/statistics',
            icon: 'el-icon-data-line',
        },
        {
            id: '105',
            label: '导入导出',
            path: '/main/statistics',
            icon: 'el-icon-data-line',
            children: [
                {
                    id: '105-1',
                    label: '预约数据导入',
                    path: '/main/data/appoint/import',
                    icon: 'el-icon-menu',
                },
            ]
        },
        {
            id: '110',
            label: '系统设置',
            path: '/main/settings',
            icon: 'el-icon-setting',
            children: [
                // {
                //     id: '110-1',
                //     label: '机构设置',
                //     path: '/main/settings/origin',
                //     icon: 'el-icon-menu',
                // },
                {
                    id: '110-2',
                    label: '手术区设置',
                    path: '/main/settings/area',
                    icon: 'el-icon-menu',
                },
                // {
                //     id: '110-3',
                //     label: '手术间设置',
                //     path: '/main/settings/theater',
                //     icon: 'el-icon-menu',
                // },
                // {
                //     id: '110-4',
                //     label: '通知单设置',
                //     path: '/main/settings/joborder',
                //     icon: 'el-icon-menu',
                // },
                {
                    id: '110-5',
                    label: '账号设置',
                    path: '/main/settings/users',
                    icon: 'el-icon-user',
                },
                {
                    id: '110-5',
                    label: '菜单设置',
                    path: '/main/settings/menu',
                    icon: 'el-icon-menu',
                },
            ]
        },
        {
            id: '120',
            label: '系统日志',
            path: '/main/oplog',
            icon: 'el-icon-notebook-1',
        }
    ],
    filterArrForKey(arr, key, value) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey(item.children, key, value)
                }
            }
            if (value.includes(item[key])) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    filterChecked(arr, key) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterChecked(item.children)
                }
            }
            return item[key]
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    }
}

export default data
